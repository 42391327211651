import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image';
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import SEO from "../components/seo"

import SubscribeForm from '../components/SubscribeForm';
import EssentialLinks from '../components/EssentialLinks'

import '../components/layout.scss';

const triggerGA = (category, label) => {
  if (category && label) {
    trackCustomEvent({
      category: category,
      action: "Click",
      label: label
    })
  }
}

const LinksPage = ({data}) => (
    <section className='links-section sky-poster'>
    <div className="sky-bg">
    <SEO title="Important Links" />
    <div className="container py-5">
      <div className="row justify-content-center w-100 mx-auto">
        <div className="col-12 col-md-6 text-center mb-4">
          {/* Leave link commented out for not, until homepage is re-done */}
          {/* <Link to='/'> */}
            <Img fluid={data.logo.childImageSharp.fluid} />
          {/* </Link> */}
        </div>
      </div>
      <div className="row w-100 justify-content-center mx-auto px-2">
        <div className="col-12 col-md-6 py-3" style={{backgroundColor: "rgba(255, 255, 255, 0.6)"}}>          
          <div className="links-container mx-2 my-2">
              <p className="text-center color-change">
                <span className="new mr-1">
                <span className="h3 one">N</span>
                <span className="h3 two">E</span>
                <span className="h3 three">W</span>
                <span className="h3 pr-2"></span>

                <span className="h3 one">U</span>
                <span className="h3 two">P</span>
                <span className="h3 three">D</span>
                <span className="h3 one">A</span>
                <span className="h3 two">T</span>
                <span className="h3 three">E</span>
                <span className="h3 one">S</span>
                {/* <span className="h3 two">S</span> */}
              </span>
            </p>
            <p>We are back on SutroFM.net Radio with a new mix series! ⬇️</p>
            <p className="text-link">
              🌬️{" "}
              <a href="https://blog.silvaelectronics.net/post/751140236301582337/se-podcast-1-michael-claus" target="_blank" rel="noopener noreferrer" style={{color: '#ffff00', backgroundColor: '#000000'}} onClick={() => triggerGA('links-page', 'se-podcast-1-michael-claus')}>
                SE Podcast 1: Michael Claus
              </a> 
              💨
            </p>
            <p>⬆️ Listen and read the interview</p>
            <hr className='mb-3'/>
            <h5 className="text-center mb-3">
              Releases
            </h5>
            <div className="text-link-container">
              <p className="text-link">
                <a href="https://solotape.bandcamp.com/album/days-on-end-pt-2-cs" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se19-liminal-point-slow-blink')}>
                  [SE20] | 'Days On End (pt. 2)' by solotape
                </a>
              </p>
              <p className="text-link">
                <a href="https://silvaelectronics.bandcamp.com/album/liminal-point" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se19-liminal-point-slow-blink')}>
                  [SE19] | 'Liminal Point' by Slow Blink
                </a>
              </p>
              <p className="text-link">
                <a href="https://nervousoperator.bandcamp.com/album/psychic-diffraction" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se18-psychic-diffusion-nerv-op')}>
                  [SE18] | 'Psychic Diffraction' by Nervous Operator
                </a>
              </p>
              <p className="text-link">
                <a href="https://michaelclaus.bandcamp.com/album/dark-forest" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se17-dark-forest-michael-claus')}>
                  [SE17] | 'Dark Forest' by Michael Claus (feat. Lara Sarkissian & Force Placement)
                </a>
              </p>
              <p className="text-link">
                <a href="https://aakaara.bandcamp.com/album/obsidian-promises" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se16-obsidian-promises-aakaara')}>
                  [SE16] | 'OBSIDIAN PROMISES' by AAKAARA
                </a>
              </p>
              <p className="text-link">
                <a href="https://solotape.bandcamp.com/album/days-on-end-pt-i" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se15-days-on-end-solotape')}>
                  [SE15] | 'Days On End (pt. 1)' by solotape
                </a>
              </p>
              <p className="text-link">
                <a href="https://juneunit.bandcamp.com/album/boskage-remixes" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se14-boskage-remixes-june-unit')}>
                  [SE14] | 'boskage (Remixes)' by juneunit
                </a>
              </p>
              <p className="text-link">
                <a href="https://soundcloud.com/silvaelectronics/se-13-juneunit-boskagecassette" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se13-boskage-june-unit')}>
                  [SE13] | 'boskage' (Cassette) by juneunit
                </a>
              </p>
              <p className="text-link">
                <a href="https://soundcloud.com/silvaelectronics/se-12-golden-donna-the-damage-has-been-done-ep-12" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se12-damage-has-been-done-golden-donna')}>
                  [SE12] | 'The Damage Has Been Done' Ep (12") by Golden Donna
                </a>
              </p>
              <p className="text-link">
                <a href="https://soundcloud.com/silvaelectronics/se-11-michael-claus-realm-ep" target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'se11-realm-mc-previews')}>
                  [SE11] | 'Realm' Ep by Michael Claus
                </a>
              </p>
            </div>
            <hr />
            <h5 className="text-center mb-3">
              Other important Links
            </h5>
            <p className="text-link">
              <a href="https://soundcloud.com/one-child-policy/lgr-massi-npl-michael-claus-august-25th-2024"  target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'mc-one-child-lower-grand')}>
                <span style={{color: '#0f0'}}>Lower Grand Radio</span> - One Child Policy feat: Massi NPL & Michael Claus
              </a>
            </p>
            <p className="text-link">
              <a href="https://ra.co/reviews/34410" target="_blank" rel="noopener noreferrer"  onClick={() => triggerGA('links-page', 'ra-review-gd-12')}>
                <span style={{color: '#fc0fc0'}}>Resident Advisor</span> review Golden Donna - The Damage Has Been Done 12"
              </a>
            </p>
            <p className="text-link">
              <a href="https://soundcloud.com/mutantradio/fraudulence-w-rodney-khalaschi-guest-michael-claus-10122021"  target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'mc-roduney-mutant-mix')}>
                Mutant Radio w/ host Rodney Khalaschi and special guest Michael Claus
              </a>
            </p>
            <p className="text-link">
              <a href="https://soundcloud.com/m50/michael-claus-alex-steiner-drnojoke-m50-etc-20210827"  target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'mc-m50-mix')}>
                Michael Claus + Alex Steiner + Dr.Nojoke + m50 @ etc 
              </a>
            </p>
            <p className="text-link">
              <a href="https://soundcloud.com/theblacklodgela/blradio-ep-72-silva-electronics"  target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'mc-black-lodge-radio-mix')}>
                Black Lodge Radio (LA) EP 72: SILVA ELECTRONICS
              </a>
            </p>
            <p className="text-link">
              <a href="https://soundcloud.com/deephouse-tehran/tehran-night-306-michael-claus"  target="_blank" rel="noopener noreferrer" onClick={() => triggerGA('links-page', 'mc-deep-house-tehran-podcast')}>
                Michael Claus mix for Deep House Tehran podcast
              </a>
            </p>
          </div>
            <hr />
            <p style={{color: '#00ff00', backgroundColor: '#000000'}}><strong>Free Music Downloads:</strong> <a href='/free/' target='_blank' style={{color: "#fc0fc0"}} rel='noopener noreferrer' onClick={() => triggerGA('links-page', 'free-downloads')}>CLICK HERE</a></p>
            {/* Essential links */}
            <hr />
            <EssentialLinks gaCategory={'links-page'}/>
            <hr />
            <h3 className="color-change text-center my-3 mb-0">Newsletter</h3>
            <SubscribeForm />
          </div>
        </div>
      </div>
    </div>    
  </section>
)

export const query = graphql`
  {
    logo: file(relativePath: {eq: "silva_electronics_logo-knife.png"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default LinksPage
